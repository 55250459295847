import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { BreadcrumbState, UIContext } from '@ev/eva-container-api';
import { noop } from 'lodash-es';

import { DesktopTopBarHeightStandalone } from '~/layouts/DesktopTopBar';
import { MobileTopBarHeightStandalone } from '~/layouts/MobileTopBar';

export function UIContextProvider({ children }: PropsWithChildren) {
  const [isLayoutDeactivated, setLayoutDeactivated] = useState<boolean>(false);
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbState>({
    current: '',
    breadcrumbs: [],
  });
  const clearBreadcrumb = useCallback(() => setBreadcrumb({ current: '', breadcrumbs: [] }), []);

  const context = useMemo(
    () => ({
      isLayoutDeactivated,
      deactivateLayout: setLayoutDeactivated,
      breadcrumb,
      setBreadcrumb,
      clearBreadcrumb,
      desktopTopBarHeight: DesktopTopBarHeightStandalone,
      mobileTopBarHeight: MobileTopBarHeightStandalone,
      // Drawer state is only relevant in EVA context
      setDrawerOpen: noop,
      getIsDrawerOpen: () => false,
      isAnyDrawerOpen: false,
    }),
    [breadcrumb, clearBreadcrumb, isLayoutDeactivated],
  );

  return <UIContext.Provider value={context}>{children}</UIContext.Provider>;
}
